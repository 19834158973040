import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Services.scss';
import ServicesData from './ServiceData';
import Service from '../../components/Service/Service';
import { Link } from 'react-router-dom';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Services = () => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <SectionTitle title="Îngrijire medicală personalizată pentru fiecare nevoie" subTitle="Servicii"/>
                    </div>
                    <div className="col-lg-6 col-sm-6">
                        <p className='service-title-text'>De la ortopedie la nutriție și psihologie, clinica noastră îți pune la dispoziție specialiști dedicați care colaborează pentru a îți asigura o recuperare completă și un stil de viață sănătos.</p>
                    </div>
                </div>

                <div className="services-slider">
                    <Slider {...settings}>
                        {ServicesData.map((service, index) => (
                            <div key={index} className="slider-item">
                                <Service 
                                    icon={service.icon}
                                    title={service.title}
                                    description={service.description}
                                    path={service.path}
                                />
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>

            <div className="services-link text-center">
                <Link to='/ortopedie'>
                    Vezi toate serviciile
                    <BsFillArrowRightCircleFill/>
                </Link>
            </div>
        </section>
    );
};

export default Services;