import React from 'react';
import './Navbar.scss';
import logo from './../../assets/logo_anastasia2.png';
import {Link} from 'react-router-dom';

const Navbar = () => {
    const handleServiceClick = (e) => {
        // Close the mobile menu
        const navbarCollapse = document.getElementById('navbarSupportedContent');
        if (navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
        }

        // Close any open dropdown menu
        const dropdownMenus = document.querySelectorAll('.dropdown-menu.show');
        dropdownMenus.forEach(menu => menu.classList.remove('show'));
    };

    // Close mobile menu when clicking any nav link
    const handleNavLinkClick = () => {
        const navbarCollapse = document.getElementById('navbarSupportedContent');
        if (navbarCollapse.classList.contains('show')) {
            navbarCollapse.classList.remove('show');
        }
    };

    // Handle dropdown toggle click
    const handleDropdownToggle = (e) => {
        e.preventDefault();
        e.stopPropagation();
        
        // Găsim dropdown menu-ul indiferent dacă s-a făcut click pe link sau săgeată
        const dropdownMenu = e.target.tagName === 'SPAN' 
            ? e.target.parentElement.nextElementSibling 
            : e.target.nextElementSibling;
        
        // Close all other open dropdowns
        const allDropdowns = document.querySelectorAll('.dropdown-menu.show');
        allDropdowns.forEach(menu => {
            if (menu !== dropdownMenu) {
                menu.classList.remove('show');
            }
        });

        // Toggle current dropdown
        dropdownMenu.classList.toggle('show');
    };

    const navbarItems = [
        {
            name: 'Acasă',
            path: '/',
        },
        {
            name: 'Despre noi',
            path: '/despre-noi',
        },
        {
            name: 'Servicii',
            path: '#',
            dropdown: [
                {
                    name: 'Ortopedie',
                    path: '/ortopedie'
                },
                {
                    name: 'Recuperare Medicală',
                    path: '/recuperare-medicala'
                },
                {
                    name: 'Medicină Sportivă',
                    path: '/medicina-sportiva'
                },
                {
                    name: 'Dermatologie',
                    path: '/dermatologie'
                },
                {
                    name: 'Psihologie și Psihoterapie',
                    path: '/psihologie-psihoterapie'
                },
                {
                    name: 'Masaj',
                    path: '/masaj'
                }
            ]
        },
        {
            name: 'Blog',
            path: '/blogs',
        },
        {
            name: 'Contact',
            path: '/contact',
        }
    ];

    return (
        <div className='main-nav'>
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        {/* Logo */}
                        <Link className="navbar-brand" to="/">
                            <img src={logo} alt="logo" />
                        </Link>
                        <button 
                            className="navbar-toggler" 
                            type="button" 
                            onClick={() => {
                                const navbarCollapse = document.getElementById('navbarSupportedContent');
                                navbarCollapse.classList.toggle('show');
                            }}
                            aria-controls="navbarSupportedContent" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
                               { 
                                navbarItems.map ((navSingle, index) => (
                                    <li className={`nav-item ${navSingle.dropdown ? 'dropdown' : ''}`} key={index}>
                                        {navSingle.dropdown ? (
                                            <>
                                                <Link 
                                                    className="nav-link" 
                                                    to={navSingle.path}
                                                    onClick={handleDropdownToggle}
                                                >
                                                    {navSingle.name}
                                                    <span 
                                                        className="dropdown-toggle ms-1"
                                                        onClick={handleDropdownToggle}
                                                        aria-expanded="false"
                                                    ></span>
                                                </Link>
                                                <ul className="dropdown-menu">
                                                    {navSingle.dropdown.map((dropItem, dropIndex) => (
                                                        <li key={dropIndex}>
                                                            <Link 
                                                                className="dropdown-item" 
                                                                to={dropItem.path}
                                                                onClick={(e) => handleServiceClick(e, dropItem.path)}
                                                            >
                                                                {dropItem.name}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        ) : (
                                            <Link 
                                                className="nav-link" 
                                                to={navSingle.path}
                                                onClick={handleNavLinkClick}
                                            >
                                                {navSingle.name}
                                            </Link>
                                        )}
                                    </li>
                                ))
                               }
                            </ul>
                            
                            {/* Navbar Button */}
                            <div className="theme-btn">
                                <Link to="/programare" onClick={handleNavLinkClick}>Programează-te</Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Navbar;