import React from 'react';
import './Psychology.scss';
import psychologyImage from '../../assets/servicePage/psychology.png';

const Psychology = () => {
    return (
        <section id="psihologie" className='psychology-section section-common section-bg'>
            <div className="container">
                {/* Secțiunea Despre Psihologie */}
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="psychology-text" data-aos="fade-up" data-aos-duration="2000">
                            <h2>Psihologie și Psihoterapie</h2>
                            <p>
                                Oferim o gamă extinsă de servicii psihologice, de la psihologie clinică și nutrițională, până la psihoterapie individuală, de cuplu sau de grup, consiliere și suport în diverse situații de criză. Abordăm dificultăți emoționale (anxietate, depresie, fobii, atacuri de panică), tulburări de comportament alimentar, probleme relaționale și de adaptare, dar și optimizare personală, autocunoaștere și coaching.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="psychology-img" data-aos="fade-up" data-aos-duration="2000">
                            <img src={psychologyImage} alt="Psihologie" />
                        </div>
                    </div>
                </div>

                {/* Psihologie Clinică */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Psihologie Clinică</h4>
                    <ul>
                        <li>Evaluare și diagnostic psihologic (factori cognitivi, afectivi, comportamentali, de personalitate) - 300 ron</li>
                        <li>Examinări neuropsihologice (atenție, memorie, limbaj, gândire) - 300 ron</li>
                        <li>Psihodiagnostic și evaluare clinică - 300 ron</li>
                    </ul>
                </div>

                {/* Consiliere Psihologică și Psihoterapie */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Consiliere Psihologică și Psihoterapie</h4>
                    <ul>
                        <li>Psihoterapie individuală - 250 ron/sedință</li>
                        <li>Psihoterapie de cuplu și familie - 300 ron/sedință</li>
                        <li>Terapie suportivă pentru bolnavi cronici - 250 ron/sedință</li>
                        <li>Consiliere pentru copii și adolescenți - 250 ron/sedință</li>
                        <li>Dezvoltare personală și autocunoaștere - 250 ron/sedință</li>
                    </ul>
                </div>

                {/* Psihologia Muncii și Organizațională */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Psihologia Muncii și Organizațională</h4>
                    <ul>
                        <li>Avize psihologice pentru angajare și control periodic - 150 ron</li>
                        <li>Evaluare și selecție de personal - 150 ron</li>
                        <li>Avize psihologice pentru concursuri/examene - 150 ron</li>
                    </ul>
                </div>

                {/* Terapii Specializate */}
                <div className="service-category" data-aos="fade-up" data-aos-duration="2000">
                    <h4>Psihoterapie Holistică și Psiho-Nutriție</h4>
                    <ul>
                        <li>Psihoterapie Holistică (1h30) - 545 ron
                            <p>Psihoterapia holistică este o formă de terapie care abordează persoana în ansamblu – minte, corp, emoții și spirit – pentru a promova vindecarea și echilibrul prin integrarea sistemică a mai multor tipuri psihoterapeutice. Spre deosebire de terapiile tradiționale care se concentrează strict pe simptomele psihologice, această abordare recunoaște interconexiunile dintre aspectele fizice, emoționale, mentale și spirituale ale individului.</p>
                        </li>
                        <li>Psiho-Nutriție (1h) - 545 ron
                            <p>Consiliere interdisciplinara care combină psihologia și nutriția pentru a înțelege și aborda relația complexă dintre emoții, comportamente, gânduri și alegerile alimentare pentru a ajuta clientul să dezvolte o relație sănătoasă cu mâncarea, să își gestioneze mai bine greutatea corporală și să își îmbunătățească starea generală de sănătate.</p>
                        </li>
                        <li>Hipnoterapie Transformațională (2h30-3h) - 1585 ron
                            <p>Este o formă avansată de hipnoterapie care se concentrează pe producerea unor schimbări profunde și de durată la nivelul minții și al comportamentului. Aceasta combină tehnici de hipnoză cu principii din psihologie, neuroștiință și dezvoltare personală pentru a ajuta oamenii să își depășească blocajele emoționale, vindecarea traumelor, schimbarea convingerilor limitative și să își atingă potențialul maxim.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default Psychology;
