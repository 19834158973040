import React from 'react';
import './SportsMedicine.scss';
import sportsMedicineImage from '../../assets/servicePage/sports-medicine.png';

const SportsMedicine = () => {
    return (
        <section id="medicina-sportiva" className='sports-medicine-section section-common section-bg'>
            <div className="container">
                {/* Sports Medicine Section */}
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <div className="sports-medicine-text" data-aos="fade-up" data-aos-duration="2000">
                            <h2>Medicină Sportivă</h2>
                            <p>
                                Avizul medico-sportiv reprezintă o verificare amănunțită și necesară pentru a stabili dacă putem practica sport în siguranță. Este obligatoriu pentru sportivii de performanță și amatori legitimați, dar se recomandă și tuturor celor care doresc să facă sport în mod organizat.
                            </p>
                            <ul className="sports-facilities">
                                <li>Evaluare completă și profesională</li>
                                <li>Personal medical specializat</li>
                                <li>Echipamente moderne</li>
                                <li>Rezultate rapide</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="sports-medicine-img" data-aos="fade-up" data-aos-duration="2000">
                            <img src={sportsMedicineImage} alt="Medicină Sportivă" />
                        </div>
                    </div>
                </div>

                {/* Sports Medicine Services Section */}
                <div className="sports-services-section" data-aos="fade-up" data-aos-duration="2000">
                    <h3>Servicii Medicină Sportivă</h3>
                    
                    {/* Examinations */}
                    <div className="service-category">
                        <h4>Examinare Completă</h4>
                        <ul>
                            <li>Examen clinic general</li>
                            <li>EKG 12 derivații</li>
                            <li>Tensiune arterială</li>
                            <li>Alură ventriculară</li>
                            <li>Probă Ruffier (test efort)</li>
                            <li>Măsurători (talie, greutate)</li>
                        </ul>
                    </div>

                    {/* Services and Prices */}
                    <div className="service-category">
                        <h4>Servicii și Tarife</h4>
                        <ul>
                            <li>Consultație Medico-Sportivă - 300 ron</li>
                            <li>Avizare Medico-Sportivă:
                                <ul>
                                    <li>Până în 13 ani - 120 ron</li>
                                    <li>Peste 13 ani - 190 ron</li>
                                </ul>
                            </li>
                            <li>Măsurători Antropometrice - 150 ron</li>
                        </ul>
                    </div>

                    {/* Important Information */}
                    <div className="important-info">
                        <h4>Informații Importante</h4>
                        <p>
                            În momentul prezentării la consultație vă rugăm să aveți următoarele: adeverință de la medicul de familie care să ateste că nu sunteți în evidență cu patologii cronice, neuropsihice sau infecțioase.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SportsMedicine;
