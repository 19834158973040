import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../sections/Footer/Footer';
import ServiceBanner from '../sections/ServiceBanner/ServiceBanner';
import Appointment from '../sections/Appointment/Appointment';
import Psychology from "../sections/Servicii/Psychology";

const PsihologiePsihoterapie = () => {
    return (
        <>
            <Navbar />
            <ServiceBanner />
            <Psychology />
            <Appointment />
            <Footer />
        </>
    );
};

export default PsihologiePsihoterapie;
