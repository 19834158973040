import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BsCheckCircleFill } from 'react-icons/bs';
import Navbar from '../components/Navbar/Navbar';
import SectionTitle from '../components/SectionTitle/SectionTitle';
import Footer from '../sections/Footer/Footer';
import './ThankYou.scss';

const ThankYou = () => {
    useEffect(() => {
        // Google Ads Conversion Tracking
        window.gtag('event', 'conversion', {
            'send_to': 'AW-16816127338/yXGhCM_UxoIaEOr6xtI-'
        });
    }, []);

    return (
        <>
            <section className='section-bg section-common thank-you-header'>
                <Navbar />
                <div className="container">
                    <SectionTitle
                        title="Mulțumim pentru încredere!"
                        description="Solicitarea dumneavoastră a fost trimisă cu succes. Vom reveni în cel mai scurt timp posibil pentru confirmare."
                    />
                </div>
            </section>

            <section className='thank-you-content' data-aos="fade-up" data-aos-duration="2000">
                <div className="container">
                    <div className="content-wrapper">
                        <div className="icon-wrapper">
                            <BsCheckCircleFill />
                        </div>
                        
                        <h2>Ce urmează?</h2>
                        <ul>
                            <li>Vom analiza solicitarea dumneavoastră cu atenție</li>
                            <li>Vă vom contacta în cel mai scurt timp posibil pentru confirmare</li>
                            <li>Vom stabili împreună toate detaliile necesare</li>
                            <li>Vă vom oferi cele mai bune soluții pentru nevoile dumneavoastră</li>
                        </ul>

                        <div className="buttons">
                            <Link to="/" className="btn-primary">
                                Înapoi la pagina principală
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default ThankYou;
