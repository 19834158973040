import React from 'react';
import './Features.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import IconList from '../../components/IconList/IconList';
import featuresData from './FeaturesData';

const Features = () => {
    return (
        <section className='section-bg section-common features-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                    <SectionTitle subTitle="Parteneri Anastasia" title="Acceptăm asigurarea ta" description="Pentru a veni in sprijinul pacienților am ales să punem la dispoziția lor decontarea serviciilor prin asigurare de stat sau privată. Indiferent de tipul asigurării, îți garantăm un proces simplu și rapid pentru a beneficia de toate serviciile noastre medicale cu minim de efort din partea ta."/>

                <div className="row align-items-center">
                    {
                        featuresData.map(singleFeature => 
                            <IconList 
                                icon={singleFeature.icon} 
                                title={singleFeature.title}
                                description={singleFeature.description}
                            />
                        )
                    }
                </div>
            </div>
        </section>
    );
};

export default Features;