import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactForm.scss';
import icon from '../../assets/banner/icons/Calling3.png';

const ContactForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        service: 'Ortopedie',
        phone: '',
        message: ''
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            fetch('mail/mail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'scr290!W@',
                },
                body: new URLSearchParams({
                    name: formData.name,
                    email: formData.email,
                    service: formData.service,
                    phone: formData.phone,
                    message: formData.message,
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    navigate('/pagina-confirmare');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    alert("A aparut o eroare. Mesajul nu a fost trimis.");
                });
        }
    };

    const validateForm = () => {
        const { name, email, phone, message } = formData;
        if (!name || !email || !phone || !message) {
            alert('Toate câmpurile sunt obligatorii. Vă rugăm să le completați.');
            return false;
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            alert('Adresa de email nu este validă. Vă rugăm să introduceți o adresă corectă.');
            return false;
        }
        const phonePattern = /^\+?\d{10,15}$/;
        if (!phonePattern.test(phone)) {
            alert('Numărul de telefon nu este valid. Vă rugăm să introduceți un număr corect.');
            return false;
        }
        return true;
    };

    return (
        <form onSubmit={handleSubmit} method="POST">
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Nume</label>
                        <input type="text" className="form-control" placeholder="Cum te numești?" name="name" value={formData.name} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Email</label>
                        <input type="text" className="form-control" placeholder="Care este adresa ta de email?" name="email" value={formData.email} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Servicii</label>
                        <select className="form-control" name="service" value={formData.service} onChange={handleInputChange}>
                            <option>Ortopedie</option>
                            <option>Recuperare Medicală</option>
                            <option>Medicină Sportivă</option>
                            <option>Psihologie și Psihoterapie</option>
                            <option>Dermatologie</option>
                            <option>Masaj</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label>Telefon</label>
                        <input type="text" className="form-control" placeholder="Care este numărul tău de telefon?" name="phone" value={formData.phone} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="form-group">
                        <label>Mesaj</label>
                        <textarea className="form-control" placeholder='Mesajul Dvs' rows="3" name="message" value={formData.message} onChange={handleInputChange}></textarea>
                    </div>
                </div>
                <div className="col-lg-6">
                    <button type="submit" className="btn appointment-btn">Trimite</button>
                </div>
                <div className="col-lg-6">
                    <div className="appointment-call">
                        <div className='icon'>
                            <a href="tel:+40319448"><img src={icon} alt="icon"/></a>
                        </div>
                        <div className='call-text'>
                            <p>Program: L-V 09-22</p>
                            <h6><a href="tel:+40319448" style={{textDecoration: 'none', color: 'inherit'}}>031 9448</a></h6>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
