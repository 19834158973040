import React from 'react';
import { Link } from 'react-router-dom';
import ThemeIcon from '../ThemeIcon/ThemeIcon';
import './Service.scss';

const Service = ({ icon, title, description, path }) => {
    return (
        <div className="service-box">
            <div className="service-icon">
                <div className='icon-area'>
                    <Link to={path}>
                        <ThemeIcon icon={icon} />
                    </Link>
                </div>
            </div>
            <div className="service-text">
                <h3><Link to={path}>{title}</Link></h3>
                <p>{description}</p>
            </div>
        </div>
    );
};

export default Service;