import './App.scss';
import Home from './pages/Home';
import {Routes, Route} from 'react-router-dom';
import About from './pages/About';
import Ortopedie from './pages/Ortopedie';
import RecuperareMedicala from './pages/RecuperareMedicala';
import Dermatologie from './pages/Dermatologie';
import PsihologiePsihoterapie from './pages/PsihologiePsihoterapie';
import MedicinaSportiva from './pages/MedicinaSportiva';
import Masaj from './pages/Masaj';
import BlogsPage from './pages/BlogsPage';
import SingleBlog from './pages/SingleBlog/SingleBlog';
import Contactus from './pages/Contact/Contactus';
import Programare from './pages/programare/Programare';
import ThankYou from './pages/ThankYou';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import CookieConsent from 'react-cookie-consent';
import useGoogleAnalytics from './hooks/useGoogleAnalytics';

function App() {
  useGoogleAnalytics();

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/despre-noi" element={<About />} />
        <Route path="/ortopedie" element={<Ortopedie />} />
        <Route path="/recuperare-medicala" element={<RecuperareMedicala />} />
        <Route path="/medicina-sportiva" element={<MedicinaSportiva />} />
        <Route path="/dermatologie" element={<Dermatologie />} />
        <Route path="/psihologie-psihoterapie" element={<PsihologiePsihoterapie />} />
        <Route path="/masaj" element={<Masaj />} />
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/blog/:url" element={<SingleBlog />} />
        <Route path="/contact" element={<Contactus />} />
        <Route path="/programare" element={<Programare />} />
        <Route path="/pagina-confirmare" element={<ThankYou />} />
        <Route path="/politica-de-confidentialitate" element={<PrivacyPolicy />} />
        <Route path="/termeni-si-conditii" element={<TermsAndConditions />} />
      </Routes>
      
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Refuz"
        cookieName="gdpr-consent"
        style={{
          background: "#66C3D0",
          padding: "20px 40px",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "20px",
          boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
          zIndex: 9999,
          maxWidth: "100%"
        }}
        buttonStyle={{
          background: "#4CAF50",
          color: "white",
          fontSize: "14px",
          padding: "12px 25px",
          borderRadius: "5px",
          fontWeight: "600",
          margin: "0 10px",
          cursor: "pointer",
          border: "none",
          transition: "background-color 0.3s ease"
        }}
        declineButtonStyle={{
          background: "transparent",
          border: "2px solid #ffffff",
          color: "white",
          fontSize: "14px",
          padding: "12px 25px",
          borderRadius: "5px",
          fontWeight: "600",
          cursor: "pointer",
          transition: "background-color 0.3s ease"
        }}
        contentStyle={{
          flex: "1",
          margin: "0",
          textAlign: "left"
        }}
        buttonWrapperClasses="cookie-buttons"
        overlayStyle={{
          background: "rgba(0, 0, 0, 0.5)"
        }}
        enableDeclineButton
        flipButtons
        overlay
      >
        <div style={{ maxWidth: "800px" }}>
          <p style={{ marginBottom: "10px", fontSize: "18px", fontWeight: "bold" }}>🍪 Acest site folosește cookie-uri</p>
          <p style={{ margin: "0", lineHeight: "1.5" }}>
            Folosim cookie-uri pentru a vă îmbunătăți experiența de navigare și pentru a analiza traficul site-ului.{" "}
            <a 
              href="/politica-de-confidentialitate" 
              style={{ 
                color: "#245775",
                textDecoration: "underline",
                fontWeight: "600"
              }}
            >
              Află mai multe despre politica noastră de confidențialitate
            </a>
          </p>
        </div>
      </CookieConsent>
    </>
  );
}

export default App;
