import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import Navbar from '../../components/Navbar/Navbar';
import './Programare.scss';
import Footer from '../../sections/Footer/Footer';
import { AiFillHome } from "react-icons/ai";

const teams = {
    'Ortopedie': [
        { 'name': 'Dr. Suchoveschi Dan' },
        { 'name': 'Dr. Stegărescu Sorin' },
        { 'name': 'Dr. Stoian Cătălin' },
        { 'name': 'Dr. Bîju Constantin' }
    ],
    'Recuperare Medicală': [
        { 'name': 'Dr. Firan Carmen' },
        { 'name': 'Dr. Iordache Liviu' },
        { 'name': 'Dr. Tirna Iuliana' }
    ],
    'Medicină Sportivă': [
        { 'name': 'Dr. Cezar Bobocea' }
    ],
    'Psihologie și Psihoterapie': [
        { 'name': 'Barbu Iulia' },
        { 'name': 'Barbu Eugen' },
        { 'name': 'Bianca Pătrașcu' }
    ],
    'Dermatologie': [
        { 'name': 'Dr. Arsene Gabriel' }
    ]
};

const Programare = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        nume: '',
        prenume: '',
        phone: '',
        email: '',
        service: '',
        medic: '',
        dataprogramare: '',
        acceptTerms: false,
        acceptPolicy: false
    });

    const [availableMedics, setAvailableMedics] = useState([]); // To handle dynamic doctors list

    const handleServiceChange = (e) => {
        const selectedService = e.target.value;
        setFormData({ ...formData, service: selectedService, medic: '' });
        setAvailableMedics(teams[selectedService] || []); // Update the list of available doctors
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            fetch('mail/mail.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'scr290!W@',
                },
                body: new URLSearchParams({
                    nume: formData.nume,
                    prenume: formData.prenume,
                    phone: formData.phone,
                    email: formData.email,
                    service: formData.service,
                    medic: formData.medic,
                    dataprogramare: formData.dataprogramare,
                    acceptTerms: formData.acceptTerms,
                    acceptPolicy: formData.acceptPolicy
                }),
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    navigate('/pagina-confirmare');
                })
                .catch((error) => {
                    console.error('Error:', error);
                    alert("A aparut o eroare. Solicitarea nu a fost trimisă.");
                });
        }
    };

    const validateForm = () => {
        const { nume, prenume, phone, email, service, medic, dataprogramare, acceptTerms, acceptPolicy } = formData;
        if (!nume || !prenume || !phone || !email || !service || !medic || !dataprogramare) {
            alert('Toate câmpurile sunt obligatorii. Vă rugăm să le completați.');
            return false;
        }
        if (!acceptTerms || !acceptPolicy) {
            alert('Trebuie să acceptați termenii și condițiile și politica de confidențialitate.');
            return false;
        }
        const telefonPattern = /^\+?\d{10,15}$/;
        if (!telefonPattern.test(phone)) {
            alert('Numărul de telefon nu este valid. Vă rugăm să introduceți un număr corect.');
            return false;
        }
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(email)) {
            alert('Adresa de email nu este validă. Vă rugăm să introduceți o adresă corectă.');
            return false;
        }
        return true;
    };

    const mapLink = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d45562.48934142542!2d26.089289180719007!3d44.46073312508191!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b1ff209ebdb5fd%3A0xd9b44cc22bcf09f3!2sPhoenicia%20Business%20Center!5e0!3m2!1sro!2sro!4v1728917752980!5m2!1sro!2sro';

    return (
        <>
            <section className='section-bg section-common programare-section'>
                <Navbar />
                <SectionTitle
                    title="Programează-te"
                    description="Programează-te rapid și ușor folosind formularul nostru. Echipa noastră va reveni la dumneavoastră pentru confirmarea programării cât mai curând posibil."
                />
            </section>

            <section className='programare-form-area' data-aos="fade-up" data-aos-duration="2000">
                <form className="contact-form" onSubmit={handleSubmit} method="POST">
                    <div className="form-row">
                        <div className="form-group">
                            <label>Nume</label>
                            <input type="text" name="nume" value={formData.nume} onChange={handleChange} placeholder="Nume" />
                        </div>
                        <div className="form-group">
                            <label>Prenume</label>
                            <input type="text" name="prenume" value={formData.prenume} onChange={handleChange} placeholder="Prenume" />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Telefon</label>
                            <input type="text" name="phone" value={formData.phone} onChange={handleChange} placeholder="Telefon" />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input type="text" name="email" value={formData.email} onChange={handleChange} placeholder="Email" />
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Serviciu</label>
                            <select name="service" value={formData.service} onChange={handleServiceChange}>
                                <option value="">Alege un serviciu</option>
                                {Object.keys(teams).map((service) => (
                                    <option key={service} value={service}>{service}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>Medic</label>
                            <select name="medic" value={formData.medic} onChange={handleChange}>
                                <option value="">Alege un medic</option>
                                {availableMedics.map((medic, index) => (
                                    <option key={index} value={medic.name}>{medic.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group">
                            <label>Data programare</label>
                            <input type="date" name="dataprogramare" value={formData.dataprogramare} onChange={handleChange} />
                        </div>
                    </div>

                    <div className="form-check">
                        <input type="checkbox" name="acceptTerms" checked={formData.acceptTerms} onChange={handleChange} />
                        <label>Sunt de acord cu <a href="/termeni-si-conditii">Termeni și condiții</a> și <a href="/politica-de-confidentialitate">Politica de confidențialitate</a>.</label>
                    </div>

                    <div className="form-check">
                        <input type="checkbox" name="acceptPolicy" checked={formData.acceptPolicy} onChange={handleChange} />
                        <label>Sunt de acord ca datele transmise prin intermediul acestui formular să fie folosite de către Recuperare Medicala Anastasia conform politicii de confidențialitate și să fiu contactat(ă) pentru confirmarea programării.</label>
                    </div>

                    <button type="submit" className="submit-btn">Trimite solicitare</button>
                </form>
            </section>

            <section className='section-bg section-common map-section'>
                <div className="col-lg-12 col-md-12">
                    <div className="google-map full-width">
                        <iframe title='map' src={mapLink}></iframe>
                        <div className="location-name">
                            <AiFillHome />
                            <p>Strada Turturelelor Nr. 11A, Phoenicia Business Center parter</p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    );
};

export default Programare;
