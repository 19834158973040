import React from 'react';
import './PageTitle.scss';

const PageTitle = ({title,description}) => {
    return (
        <div className='page-title'>
            <div className="container">
                <h2>Blogul Recuperare Medicala Anastasia</h2>
                <p>Citiți articolele noastre pentru a afla cele mai bune recomandări medicale și ultimele știri din domeniul medical. Suntem aici să vă ajutăm să aveți grijă de sănătatea dumneavoastră.</p>
            </div>
        </div> 
    );
};

export default PageTitle;