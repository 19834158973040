import React from 'react';
import './Team.scss';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import imgOne from '../../assets/about/team/profile2.png';
import imgTwo from '../../assets/about/team/profile2.png';
import pozaBarbuEugen from '../../assets/about/team/barbu_eugen.png';
import pozaBiancaPatrascu from '../../assets/about/team/bianca_patrascu.png';
import pozaFiran from '../../assets/about/team/firan.png';
import pozaCezarBobocea from '../../assets/about/team/cezar_bobocea.png';
import pozaArseneGabriel from '../../assets/about/team/arsene_gabriel.png';
import pozaTirnaIuliana from '../../assets/about/team/tirna_iuliana.png';

const Team = () => {
    const teams = {
        'Ortopedie': [
            {
                'img': imgTwo,
                'name': 'Dr. Suchoveschi Dan',
                'info': 'Medic Ortopedie și Traumatologie<br>NCN-chirurgie spinală'
            },
            {
                'img': imgTwo,
                'name': 'Dr. Stegărescu Sorin',
                'info': 'Medic Ortopedie și Traumatologie<br>Competențe in Osteopatie, Acupunctură, Homeopatie,Urgențe prespitalicești, Medicină hiperbarică'
            },
            {
                'img': imgTwo,
                'name': 'Dr. Stoian Cătălin',
                'info': 'Chirurg Ortoped<br>Specializat în tratamentul afecțiunilor articulare și traumatismelor osteo-articulare'
            },
            {
                'img': imgTwo,
                'name': 'Dr. Bîju Constantin',
                'info': 'Chirurg Ortoped<br>Expert în diagnosticarea și tratamentul chirurgical al afecțiunilor aparatului locomotor'
            }
        ],
        'Recuperare Medicală': [
            {
                'img': pozaFiran,
                'name': 'Dr. Firan Carmen',
                'info': 'Medic Primar Reabilitare Medicală și Balneologie<br>Doctorat în neuroreabilitare'
            },
            {
                'img': imgTwo,
                'name': 'Dr. Iordache Liviu',
                'info': 'Medic Primar Reabilitare Medicală și Balneologie<br>Competențe in acupunctură'
            },
            {
                'img': pozaTirnaIuliana,
                'name': 'Dr. Tirna Iuliana',
                'info': 'Medic Specialist Reabilitare Medicală și Balneologie<br>Experiență în recuperare medicală și terapii de reabilitare'
            }
        ],
        'Medicină Sportivă': [
            {
                'img': pozaCezarBobocea,
                'name': 'Dr. Cezar Bobocea',
                'info': 'Medic Specialist Medicina Sportiva la complexul sportiv național Arcul De Triumf și medic al echipei naționale de fotbal U17. Cursuri de specializare:<br>• Curs de formare ofițer anti-doping (2022)<br>• Curs UEFA football doctor education program (2023)<br>• Curs "Ecografia aparatului musculo-scheletal la sportivi" (2024)'
            }
        ],
        'Psihologie': [
            {
                'img': imgOne,
                'name': 'Barbu Iulia',
                'info': 'Psihoterapeut<br>Consiliere clinică<br>Logopedie'
            },
            {
                'img': pozaBarbuEugen,
                'name': 'Barbu Eugen',
                'info': 'Psiholog specializat în psiho-diagnostic și evaluare clinică, oferă consiliere și terapie suportivă, adaptate nevoilor individuale pentru menținerea sănătății emoționale.'
            }
        ],
        'Dermatologie': [
            {
                'img': pozaArseneGabriel,
                'name': 'Dr. Arsene Gabriel',
                'info': 'Medic specialist dermatolog cu expertiză în diagnosticarea și tratarea afecțiunilor cutanate. Specializat în dermatologie clinică și estetică, cu focus pe tratamente inovatoare și prevenția afecțiunilor pielii.'
            }
        ],
        'Psihoterapie și Psiho-Nutritie': [
            {
                'img': pozaBiancaPatrascu,
                'name': 'Bianca Pătrașcu',
                'info': 'Psihoterapeut si Psiho-Nutritionist<br>Expertă în programe terapeutice personalizate, utilizând psihoterapie cognitiv-comportamentală, hipnoză clinică, terapie sistemică și constelații. Integrează tehnici avansate precum NLP, ACT, DBT, mindfulness, NSDR Protocol și Quantum Embodiment pentru optimizarea sănătății fizice și mentale.'
            }
        ]
    };

    return (
        <section className='team-section pt-100' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-7">
                        <SectionTitle
                            subTitle="Întâlnește Echipa Noastră"
                            title="Cunoaște Specialiștii Noștri din Fiecare Departament"
                        />
                    </div>

                    <div className="col-lg-5">
                        <p className='pt-5'>Anastasia Clinic reunește o echipă de specialiști dedicați care acoperă diverse domenii: ortopedie, dermatologie, medicină sportivă, recuperare medicală, nutriție și consiliere psihologică. Fiecare dintre noi are un singur scop - sănătatea ta.</p>
                    </div>
                </div>

                {Object.keys(teams).map((department, index) => (
                    <div key={index} className="department-section">
                        <h2 className="department-title">{department}</h2>
                        <div className="row">
                            {teams[department].map((teamMember, idx) => (
                                <div key={idx} className="col-lg-3 col-sm-6">
                                    <div className="team-card">
                                        <div className="team-img">
                                            <img src={teamMember.img} alt={teamMember.name} />
                                        </div>
                                        <h3>{teamMember.name}</h3>
                                        <p className="team-info"
                                           dangerouslySetInnerHTML={{__html: teamMember.info}}></p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
};

export default Team;