import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../sections/Footer/Footer';
import './PrivacyPolicy.scss';

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />
      <div className="privacy-policy-container">
        <div className="container mx-auto px-4 py-8">
          <div className="privacy-policy-content">
            <h1 className="text-3xl font-bold mb-8">Politica de Confidențialitate</h1>
            
            <div className="space-y-8">
              <section>
                <h2 className="text-2xl font-semibold mb-4">1. Introducere</h2>
                <p className="text-gray-700 leading-relaxed">
                  Această Politică de Confidențialitate explică modul în care colectăm, folosim și protejăm informațiile dumneavoastră atunci când utilizați site-ul nostru și serviciile noastre.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">2. Informațiile pe care le colectăm</h2>
                <p className="text-gray-700 mb-4">Colectăm următoarele tipuri de informații:</p>
                <ul className="list-disc ml-6 space-y-2 text-gray-700">
                  <li>Informații de contact (nume, email, număr de telefon)</li>
                  <li>Informații despre programări</li>
                  <li>Date despre istoricul medical (când este relevant)</li>
                  <li>Informații tehnice despre vizitele pe site</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">3. Cum folosim informațiile</h2>
                <p className="text-gray-700 mb-4">Utilizăm informațiile colectate pentru:</p>
                <ul className="list-disc ml-6 space-y-2 text-gray-700">
                  <li>Gestionarea programărilor</li>
                  <li>Comunicarea cu pacienții</li>
                  <li>Îmbunătățirea serviciilor noastre</li>
                  <li>Respectarea obligațiilor legale</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">4. Cookie-uri</h2>
                <p className="text-gray-700 leading-relaxed">
                  Site-ul nostru utilizează cookie-uri pentru a îmbunătăți experiența de navigare. 
                  Puteți controla utilizarea cookie-urilor prin setările browserului dumneavoastră.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">5. Drepturile dumneavoastră</h2>
                <p className="text-gray-700 mb-4">Conform GDPR, aveți următoarele drepturi:</p>
                <ul className="list-disc ml-6 space-y-2 text-gray-700">
                  <li>Dreptul de acces la date</li>
                  <li>Dreptul la rectificare</li>
                  <li>Dreptul la ștergerea datelor</li>
                  <li>Dreptul la restricționarea prelucrării</li>
                  <li>Dreptul la portabilitatea datelor</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">6. Contact</h2>
                <p className="text-gray-700 leading-relaxed">
                  Pentru orice întrebări legate de această politică de confidențialitate sau despre datele dumneavoastră, 
                  vă rugăm să ne contactați la:
                </p>
                <p className="text-gray-700 mt-2">Email: programari@recuperareanastasia.ro</p>
                <p className="text-gray-700">Telefon: 031 9448</p>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
