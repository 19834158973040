import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../sections/Footer/Footer';
import './TermsAndConditions.scss';

const TermsAndConditions = () => {
  return (
    <>
      <Navbar />
      <div className="terms-container">
        <div className="container mx-auto px-4 py-8">
          <div className="terms-content">
            <h1 className="text-3xl font-bold mb-8">Termeni și Condiții</h1>
            
            <div className="space-y-8">
              <section>
                <h2 className="text-2xl font-semibold mb-4">1. Introducere</h2>
                <p className="text-gray-700 leading-relaxed">
                  Vă mulțumim că ați ales serviciile Recuperare Medicală Anastasia. Acești termeni și condiții descriu regulile și reglementările pentru utilizarea serviciilor noastre.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">2. Programări și Consultații</h2>
                <p className="text-gray-700 mb-4">Următoarele reguli se aplică pentru programări și consultații:</p>
                <ul className="list-disc ml-6 space-y-2 text-gray-700">
                  <li>Programările se pot face online, telefonic sau la recepția clinicii</li>
                  <li>Este necesară prezentarea cu 10 minute înainte de ora programată</li>
                  <li>Anularea programării trebuie făcută cu cel puțin 24 de ore înainte</li>
                  <li>În cazul întârzierii, timpul consultației se va scurta corespunzător</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">3. Servicii și Tarife</h2>
                <p className="text-gray-700 mb-4">Informații despre serviciile și tarifele noastre:</p>
                <ul className="list-disc ml-6 space-y-2 text-gray-700">
                  <li>Tarifele sunt afișate la recepție și pe site-ul nostru</li>
                  <li>Plata se efectuează înainte de începerea ședinței</li>
                  <li>Acceptăm plăți în numerar și cu cardul</li>
                  <li>Prețurile pot fi modificate, cu notificare prealabilă</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">4. Drepturi și Responsabilități</h2>
                <p className="text-gray-700 mb-4">Drepturile și responsabilitățile pacienților:</p>
                <ul className="list-disc ml-6 space-y-2 text-gray-700">
                  <li>Dreptul la informare completă despre tratamente</li>
                  <li>Responsabilitatea de a furniza informații medicale corecte</li>
                  <li>Dreptul la confidențialitatea datelor medicale</li>
                  <li>Respectarea indicațiilor și recomandărilor medicale</li>
                </ul>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">5. Politica de Anulare</h2>
                <p className="text-gray-700 leading-relaxed">
                  Anularea programării cu mai puțin de 24 de ore înainte sau neprezentarea poate rezulta în pierderea avansului sau aplicarea unei taxe de reprogramare.
                  Situațiile excepționale vor fi analizate individual.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">6. Proprietate Intelectuală</h2>
                <p className="text-gray-700 leading-relaxed">
                  Toate materialele prezentate pe site (logo, texte, imagini) sunt proprietatea Recuperare Medicală Anastasia și sunt protejate de legile privind drepturile de autor.
                </p>
              </section>

              <section>
                <h2 className="text-2xl font-semibold mb-4">7. Contact</h2>
                <p className="text-gray-700 leading-relaxed">
                  Pentru orice întrebări legate de acești termeni și condiții, vă rugăm să ne contactați la:
                </p>
                <p className="text-gray-700 mt-2">Email: programari@recuperareanastasia.ro</p>
                <p className="text-gray-700">Telefon: 031 9448</p>
              </section>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;
