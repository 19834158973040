import iconOrtopedie from '../../assets/service/ortopedie.png';
import iconRecuperare from '../../assets/service/recuperare_medicala.png';
import iconMedicinaSportiva from '../../assets/service/medicina_sportiva.png';
import iconDermatologie from '../../assets/service/dermatologie.png';
import iconPsihoterapie from '../../assets/service/psihoterapie.png';
import iconNutritie from '../../assets/service/nutritie.png';
import iconMasaj from '../../assets/service/masaj.png';

const ServicesData = [
    {
        'icon': iconOrtopedie,
        'title': 'Ortopedie',
        'description': 'Tratăm afecțiunile aparatului locomotor cu specialiști în ortopedie.',
        'path': '/ortopedie'
    },
    {
        'icon': iconRecuperare,
        'title': 'Recuperare Medicală',
        'description': 'Servicii de recuperare medicală pentru a-ți reda mobilitatea și sănătatea.',
        'path': '/recuperare-medicala'
    },
    {
        'icon': iconMedicinaSportiva,
        'title': 'Medicină Sportivă',
        'description': 'Servicii specializate pentru sportivi și persoane active.',
        'path': '/medicina-sportiva'
    },
    {
        'icon': iconDermatologie,
        'title': 'Dermatologie',
        'description': 'Diagnosticarea și tratamentul afecțiunilor pielii.',
        'path': '/dermatologie'
    },
    {
        'icon': iconPsihoterapie,
        'title': 'Psihoterapie',
        'description': 'Consiliere și terapie pentru echilibrul mental și emoțional.',
        'path': '/psihologie-psihoterapie'
    },
    {
        'icon': iconNutritie,
        'title': 'Nutriție',
        'description': 'Programe nutriționale personalizate pentru un stil de viață sănătos.',
        'path': '/psihologie-psihoterapie'
    },
    {
        'icon': iconMasaj,
        'title': 'Masaj',
        'description': 'Servicii de masaj terapeutic și de relaxare pentru bunăstarea ta.',
        'path': '/masaj'
    }
]

export default ServicesData;